import * as React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import { PATH_AFTER_FROM_LOGIN, SITE_TITLE } from '@src/config/constants';
import { userAtom } from '@src/recoil/atoms/user';
import { FormLogin } from '@src/services/auth/compoments/FormLogin';

export interface LoginProps {}

/**
 * @name Login
 * @description Login page with a simple form and some links to the
 * standard "forgot" and "reset" password flows
 */
export const Login = (_props: LoginProps) => {
  // Hooks
  const { visitor } = useRecoilValue(userAtom);
  const { push } = useRouter();

  // Styles
  const cssComponent = `flex flex-1 flex-col items-center justify-center bg-sea-salt`;

  // Life Cycle
  React.useEffect(() => {
    if (!visitor) push(PATH_AFTER_FROM_LOGIN);
  }, [visitor]);

  return (
    <div className={cssComponent}>
      <Head>
        <title>Login | {SITE_TITLE}</title>
      </Head>

      <main className="ui-container-sm mt-6x md:mt-8x">
        <h1 className="ui-page-heading-medium text-center">
          You look great today
        </h1>
        <FormLogin className="px-2x" />
      </main>
    </div>
  );
};

export default Login;
