import * as React from 'react';
import classnames from 'classnames';

import { PATH_AFTER_FROM_LOGIN, SITE_SURVEY_PATH } from '@src/config/constants';
import { useFirebase } from '@src/hooks/useFirebase';
import { useFormInput } from '@src/hooks/useFormInput';
import { useFormValidation } from '@src/hooks/useFormValidation';
import { HaldiButton } from '@src/routes/common/components/HaldiButton';
import { AppErrorMessage } from '@src/services/app/components/AppErrorMessage';
import { AppLink } from '@src/services/app/components/AppLink';

export interface FormLoginProps {
  className?: string;
}

/**
 * @name FormLogin
 * @description Basic form for login which makes use of basic HTML validation
 */
export const FormLogin = (props: FormLoginProps) => {
  const { className } = props;

  // Hooks
  const formRef = React.useRef<HTMLFormElement>(null);
  const inputEmail = useFormInput('');
  const inputPassword = useFormInput('');
  const { signIn } = useFirebase();

  const [isInvalidCredentials, setIsInvalidCredentials] = React.useState(false);
  const isValid = useFormValidation(formRef, [
    inputEmail.value,
    inputPassword.value
  ]);

  // Setup
  const errorMessage = isInvalidCredentials
    ? 'Invalid email address or password'
    : undefined;

  // Styles
  const cssBtn = classnames('w-[240px]', { disabled: !isValid });
  const cssComponent = classnames('mx-auto text-center', className);

  // Handlers
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsInvalidCredentials(false);

    // 👀  https://github.com/haldiskin/client/issues/491
    const email = inputEmail.value.toLowerCase();
    const password = inputPassword.value;

    try {
      await signIn(email, password, PATH_AFTER_FROM_LOGIN);
    } catch (e) {
      setIsInvalidCredentials(true);
    }
  };

  return (
    <form
      className={cssComponent}
      onSubmit={onSubmit}
      ref={formRef}
      style={{ maxWidth: 375 }}
    >
      <AppErrorMessage className="text-red mb-2x" message={errorMessage} />

      <label className="sr-only" htmlFor="email">
        Email
      </label>
      <input
        autoComplete="email"
        className="mb-2x"
        id="email"
        onChange={inputEmail.onChange}
        placeholder="Email"
        required
        type="email"
        value={inputEmail.value}
      />

      <label className="sr-only" htmlFor="name">
        Password
      </label>
      <input
        autoComplete="password"
        className="mb-2x"
        id="password"
        onChange={inputPassword.onChange}
        placeholder="Password"
        required
        type="password"
        value={inputPassword.value}
      />

      <AppLink
        className="my-3x block text-14 text-gray-5"
        href="/forgot-password"
      >
        Forgot password?
      </AppLink>

      <HaldiButton className={cssBtn} type="submit">
        Log In
      </HaldiButton>

      <div className="my-4x block text-14">
        New here?{' '}
        <AppLink className="font-semibold text-gray-5" href={SITE_SURVEY_PATH}>
          Get started
        </AppLink>
      </div>
    </form>
  );
};
